import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js';
const apiService = Apiservice;

export default class OnlineExamMarkerService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/online-exams-marking';
    }
    updateOnlineMarkerVoid(data={}){
        let url = `${this.#api}/update-void`;
        return apiService.post(url, data);
    }
    viewIndividualMarkedExam(id){
        let url = `${this.#api}/${id}/view-individual-marked-exam`;
        return apiService.get(url);
    }
}