import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateOnlineExamResultService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/exam-result';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getByCandidate(id) {
        let url = `${this.#api}/get/by/candidate/${id}`
        return apiService.get(url)
    }

    delete(resultId){
        let url = `${this.#api}/${resultId}`
        return apiService.delete(url)
    }

    getExamResultPdf(resultId){
        let url = `${this.#api}/feedback-report/${resultId}`
        return window.open(url, '_blank');
    }

    getDigitalCertificatePdf(resultId){
        let url = `${this.#api}/digital-certificate/${resultId}/get`
        return window.open(url, '_blank');
    }

    viewDigitalCertificatePdf(resultId){
        let url = `${this.#api}/download-result-pdf/${resultId}`
        return window.open(url, '_blank');
    }

    viewInvoice(cartId){
        let url = `${this.#api}/view-invoice/${cartId}`
        return window.open(url, "_blank");
    }
    getAllOngoingOfficialExams(data={},index = null)
    {
        let url = `${this.#api}/ongoing-official-exams`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getAllAppealRequestOnlineExamResult(data={}){
        let url = `${this.#api}/get/appeal-request`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getAllPendingOnlineExamResult(data={}, index = null){
        let url = `${this.#api}/get/pending`;
        
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        
        return apiService.query(url,param);
    }
    getAllInProgressOnlineExamResult(data={}){
        let url = `${this.#api}/get/in-progress`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getCompletedOnlineExamResult(data={}, index = null){
        let url = `${this.#api}/get/completed`;

        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url, param);
    }
    getAllCompletedOnlineExamResult(data={}){
        let url = `${this.#api}/get/all/completed`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getGradingReportOnlineExamResult(data={}){
        let url = `${this.#api}/get/grading-report`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }
    assignOnlineMarker(id, data) {
        let url = `${this.#api}/${id}/assign/online-marker`;
        return apiService.post(url, data)
    }
    getPendingCertificates(data={},index = null) {
        let url = `${this.#api}/pending/certificates`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);

    }

    getPendingLabels(data={},index = null) {
        let url = `${this.#api}/pending/labels`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    createRequestAppeal(resultId,data)
    {
        let url = `${this.#api}/${resultId}/request-appeal`;
        return apiService.post(url, data);
    }

    grantSecondAttempt(data)
    {
        let url = `${this.#api}/grant-second-attempt`;
        return apiService.post(url, data);
    }

    resyncDataWithRS(data)
    {
        let url = `${this.#api}/resync-data-with-rs`;
        return apiService.post(url, data);
    }

    changeCanResumeStatus(resultId){
        let url = `${this.#api}/${resultId}/change-resume-status`;
        return apiService.post(url)
    }
}
