<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Needs marking - Online exam</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ - Online exam
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field label="Enrolment key" dense outlined clearable v-model="search.exam_key"  v-on:keyup.enter="searchOngoingOnlineExam"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="9" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchOngoingOnlineExam"
                         class="mt-1 btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Candidate Name</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Exam start date/time</th>
                  <th class="px-3">Marking Status</th>

                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item,index) in ongoing_online_exams" :key="index">
                    <td class="px-3">
                      <span  class="font-weight-bolder">
                        {{item.full_name}}
                      </span>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.exam_key}}
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.product_name}}
                      </div>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 font-weight-medium text-hover-primary mb-1 font-size-sm">
                        {{item.start_time_format ? item.start_time_format : 'NA'}}
                      </a>
                    </td>
                    <td>
                      <div class="mb-1" v-if="item.marking_locked">
                        Marking in progress
                      </div>
                      <div v-else>
                        Available for marking
                      </div>
                    </td>

                    <td class="pr-0 text-left">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openDialog(item.id,item.previous_result_id)" class="navi-link" v-if="!item.is_assigned_to_online_marker">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fa-solid fa-list-check</v-icon>
                                </span>
                                <span class="navi-text">Assign To Online Marker</span>
                              </a>
                              <a @click="unAssignOnlineExamResult(item.id)" class="navi-link" v-if="item.is_assigned_to_online_marker">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fa-solid fa-list-check</v-icon>
                                </span>
                                <span class="navi-text">Unassign OnlineMarker</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="ongoing_online_exams.length == 0">
                    <td colspan="7" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="ongoing_online_exams.length > 0"
                            class="pull-right mt-7"
                            @input="getAllOngoingOfficialExams"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="assign_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
    <v-dialog v-model="assign_dialog"
              max-width="700"
              scrollable>
      <v-card>
        <v-toolbar flat>
          <v-card-title>
            <span>Assign to online marker</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12">
                <v-autocomplete v-model="assigned_to" dense outlined :items="online_markers" cache-items hide-no-data
                                hide-details
                                item-text="full_name" item-value="id"
                                :search-input.sync="onlineMarkerSearch" clearable
                                label="Assign to online marker"
                                :error="$v.assigned_to.$error">
                </v-autocomplete>
                <span class="text-danger" v-if="$v.assigned_to.$error">This information is required</span>
              </v-col>
              <v-col cols="6" md="12">
                <v-select
                    label="Marking Priority"
                    v-model="marking_priority"
                    :items="priorities"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              class="cancel-btn"
              x-large
              @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn color="btn btn-primary"
                 dark
                  x-large :loading="loading"
                 @click="updateOnlineExamResult">
            Assign
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import OnlineExamMarkerService from "@/services/online-marker/OnlineExamMarkerService";
import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";
import {required} from "vuelidate/lib/validators";

const onlineMarker=new OnlineMarkerService();
const onlineExamMarker=new OnlineExamMarkerService();
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  validations: {
    assigned_to:{required}
  },
  data(){
    return{
      search:{
        exam_key:'',

      },
      isLoading:false,
      ongoing_online_exams:[],
      dialog:false,
      loading:false,

      remarks:'',
      total: null,
      perPage: null,
      page: null,
      assign_dialog:false,
      online_markers:[],
      onlineMarkerSearch:'',
      result_id:'',
      assigned_to:null,
      marking_priority:'',
      priorities:[
        {text:'High',value:'high'},
        {text:'Normal',value:'normal'},
      ],
      assign_overlay:false,
      previous_marked_by:'',
    }
  },
  methods:{
    getAllOngoingOfficialExams(){
      this.isLoading=true;
      onlineExamResult
          .getAllPendingOnlineExamResult(this.search, this.page)
          .then(response => {
            this.ongoing_online_exams=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    searchOngoingOnlineExam(){
      this.getAllOngoingOfficialExams();
    },
    openDialog(resultId,previousResultID=null){
      this.assign_dialog=true;
      this.result_id=resultId;

      if(previousResultID){
        this.findPreviousResult(previousResultID);
      }
    },
    findPreviousResult(previousResultID){

      onlineExamResult
          .show(previousResultID)
          .then(response => {
            let result=response.data.candidateOnlineExamResult;
            if(result && result.marked_by){
              this.previous_marked_by=result.marked_by;
            }

          });
    },
    closeDialog(){
      this.assign_dialog=false;
      this.result_id=null;
      this.assigned_to=null;
      this.previous_marked_by=null;
      this.onlineMarkerSearch=null;
    },
    updateOnlineExamResult(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.assign_overlay=true;
        this.loading=true;
        let data = {
          'marking_assigned_to': this.assigned_to,
          'marking_assigned_by': this.currentUser.id,
          'marking_priority':this.marking_priority,
        }
        onlineExamResult
            .assignOnlineMarker(this.result_id, data)
            .then(response => {
              this.$snotify.success("Exam assigned");
              this.closeDialog();
              this.getAllOngoingOfficialExams();
            })
            .catch((err) => {

            })
            .finally(() => {
              this.loading=false;
              this.assign_overlay=false;
            });
      }

    },
    unAssignOnlineExamResult(resultId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.assign_overlay=true;
            let data = {
              'marking_assigned_to': null,
              'marking_assigned_by': null,
              'marking_priority':'normal',
            }
            onlineExamResult
                .update(resultId, data)
                .then(response => {
                  this.getAllOngoingOfficialExams();
                  this.$snotify.success("Exam marking assigned to marker ");
                  this.closeDialog();
                })
                .catch((err) => {

                })
                .finally(() => {
                  this.isLoading = false;
                  this.assign_overlay=false;
                });
          }
        },
      });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },

  },
  watch:{
    onlineMarkerSearch(val){
      let data={
        'name':val,
        'previous_user_id':this.previous_marked_by,
      }
      onlineMarker
          .search(data)
          .then((response) => {
              this.online_markers=response.data.onlineMarkers;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    }
  },
  mounted() {
    this.getAllOngoingOfficialExams();
    this.getCurrentUser();
  }
}
</script>